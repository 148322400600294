
export const contentSideBar = {
    'es': [
        'Todo',
        'Mío',
        'Compartido conmigo',
        'Solo archivos'
    ],

    'en':   [
        'All',
        'Mine',
        'Shared with me',
        'Just Files'
    ]
}
