
export const contentFiles = {
    'es': [
        'Archivos Encontrados',
        'Nombre',
        'Compartido conmigo',
        'Última vez abierto',
        'Sí'
    ],

    'en':   [
        'Found Files',
        'Name',
        'Shared with me',
        'Last Open',
        'Yes'
    ]
}
