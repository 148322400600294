

export const contentFooter = {
    'en': [
        'Designed and programmed by ',
        `Pablo Solano`,
        `Contribute to it`
    ],

    'es': [
        `Diseñado y programado por `,
        `Pablo Solano`,
        `Contribuir`
    ]
    
}